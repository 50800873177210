<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Mashina Brendlar Ruyhati</h3>
          </div>
          <div class="card-button">
            <router-link to="/client/clientreg/">
              <button class="btn btn-success">Brend +</button>
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <b-table striped hover :fields="fields" :items="allTruckBrands">
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      fields: [
        {
          label: 'ID',
          key: 'id'
        },
        {
          label: 'Brend Nomi',
          key: 'name'
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllTruckBrands')
  },
  computed: {
    allTruckBrands() {
      return this.$store.state.requests.allTruckBrands
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Umumiy Bulim' },
      { title: 'Mashina Brend' }
    ])
  }
}
</script>
<style></style>
